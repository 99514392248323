<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" class="previous-requests">
        <h2 class="main-color text-center">{{ $t("Review Requests") }}</h2>
        <v-card color="#EEF3FF" class="mx-auto pa-3" max-width="100%">
          <div class="employee-info text-center">
            <v-img
              :src="employeeImage ? employeeImage : '../../assets/avatar.png'"
              max-width="100px"
              class="mx-auto profile-image"
            ></v-img>
            <p class="name mb-0">{{ employeeName }}</p>
            <p class="main-color title">{{ employeeTitle }}</p>

            <!-- <p class="previous main-color">Previous Request</p> -->
            <v-divider></v-divider>
          </div>
          <v-simple-table class="requests-table">
            <template v-slot:default>
              <tbody v-if="requests.length > 0">
                <tr v-for="(item, index) in requests" :key="index">
                  <td class="px-1 main-color">
                    <v-btn
                      class="px-0 request-btn"
                      @click="getrequestData(item.id)"
                    >
                      {{ item.request_code }}
                    </v-btn>
                  </td>
                  <td class="px-1">{{ item.request_type }}</td>
                  <td class="grey--text px-1">
                    {{ item.days }}
                    <span v-if="item.request_type == 'Vacation'">D</span>
                  </td>
                  <td :class="item.status_class + ' px-1'">
                    {{ item.status }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- <p class="text-center mb-0 main-color ">View All Requests</p> -->
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" class="request">
        <!-- <v-row>
          <v-col cols="12" sm="4">
            <div class="d-flex justify-center align-center mt-7">
              <v-icon>keyboard_arrow_left</v-icon>
              <p class="mb-0 main-color">{{ requestDetails.type }}</p>
              <v-icon>keyboard_arrow_right</v-icon>
            </div>
          </v-col>
          <v-col cols="12" sm="8" class="stats">
            <div class="items d-flex justify-space-around align-center">
              <div class="item item1 text-center">
                <p class="mb-0">Total</p>
                <p class="total-num px-3 py-2">21 Days</p>
              </div>
              <div class="item item2 text-center">
                <p class="mb-0">Consumed</p>
                <p class="total-num px-3 py-2">21 Days</p>
              </div>
              <div class="item item3 text-center">
                <p class="mb-0">Remaining</p>
                <p class="total-num px-3 py-2">21 Days</p>
              </div>
            </div>
          </v-col>
        </v-row> -->

        <v-row v-if="requestDetails">
          <v-col>
            <v-card class="mx-auto pa-3">
              <v-row>
                <v-col>
                  <p class="main-color">
                    {{ requestDetails.request_code }}
                  </p>
                </v-col>
                <v-col>
                  <p :class="requestDetails.status_class + ' text-right'">
                    <v-icon
                      :class="requestDetails.status_class"
                      title="Request Status"
                      >info</v-icon
                    >
                    <span>{{ requestDetails.status }}</span>
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-row class="request-duration">
                    <v-col
                      cols="6"
                      sm="4"
                      v-if="requestDetails.type == 'vacation'"
                      class="py-1"
                    >
                      <p class="main-color">{{ $t("Days Requested") }}</p>
                      <p>{{ requestDetails.days }} {{ $t("Working Days") }}</p>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                      v-if="requestDetails.type == 'vacation'"
                      class="py-1"
                    >
                      <p class="main-color">{{ $t("Start") }}</p>
                      <p>
                        {{ requestDetails.start_date }}
                      </p>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                      v-if="requestDetails.type == 'vacation'"
                      class="py-1"
                    >
                      <p class="main-color">{{ $t("End") }}</p>
                      <p>{{ requestDetails.end_date }}</p>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                      v-if="requestDetails.type == 'permission'"
                      class="py-1"
                    >
                      <p class="main-color">{{ $t("Date") }}</p>
                      <p>{{ requestDetails.request_day }}</p>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="4"
                      v-if="requestDetails.type == 'permission'"
                      class="py-1"
                    >
                      <p class="main-color">{{ $t("Hours") }}</p>
                      <p>{{ requestDetails.days }}</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <p class="main-color">{{ $t("Reasons") }}</p>
                  <p>{{ requestDetails.reason }}</p>

                  <div class="main-color" v-if="requestDetails">
                    <p
                      class="main-color"
                      v-for="(item, index) in requestDetails.files"
                      :key="index"
                    >
                      <a :href="item.path" target="_blank">
                        <v-icon color="#7297ff">image</v-icon>
                        {{ item.file_name }}
                      </a>
                    </p>
                  </div>
                </v-col>
              </v-row>

              <v-form ref="form" v-model="valid">
                <v-col cols="12">
                  <h3 class="main-color text-center form-header">
                    {{ $t("Response") }}
                  </h3>

                  <v-row class="checkbox-container">
                    <v-col cols="6" sm="7" class="px-1">
                      <v-radio-group
                        v-model="requestDetails.request_type"
                        :rules="[validationRules.required]"
                        row
                      >
                        <v-radio :label="$t('Paid')" value="paid"></v-radio>
                        <v-radio :label="$t('Unpaid')" value="unpaid"></v-radio>
                        <v-radio
                          v-if="requestDetails.type == 'vacation'"
                          :label="$t('partially-paid')"
                          value="partialy_paid"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="5"
                      class="px-1"
                      v-if="requestDetails.type == 'vacation'"
                    >
                      <div class="d-flex align-center">
                        <label for="paidDays" style="margin-right: 5px">{{
                          $t("Paid Days")
                        }}</label>
                        <v-text-field
                          class="d-inline-block ml-1"
                          v-model="requestDetails.paied_days"
                          :rules="[
                            validationRules.required,
                            validationRules.numeric
                          ]"
                          solo
                          dense
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="py-1">
                  <label for="textarea">{{ $t("Notes From HR") }}</label>
                  <v-textarea
                    v-model="requestDetails.notes"
                    solo
                    :label="$t('28 working days')"
                  >
                  </v-textarea>
                </v-col>
              </v-form>

              <v-row>
                <v-col cols="12 text-right form-btns">
                  <v-btn
                    class="reject mr-2"
                    v-if="
                      requestDetails.status !== 'Expired' &&
                        requestDetails.status !== 'Declined'
                    "
                    @click="reject(requestDetails.id)"
                  >
                    {{ $t("Reject") }}
                  </v-btn>
                  <v-btn
                    class="Approve"
                    v-if="
                      requestDetails.status !== 'Expired' &&
                        requestDetails.status !== 'Declined' &&
                        requestDetails.status !== 'Approved'
                    "
                    @click="approve()"
                    >{{ $t("Approve") }}</v-btn
                  >

                  <v-btn
                    class="reject mr-2"
                    v-if="requestDetails.status === 'Declined'"
                    @click="unreject(requestDetails.id)"
                  >
                    {{ $t("Unreject") }}
                  </v-btn>
                  <v-btn
                    class="Approve"
                    v-if="requestDetails.status === 'Approved'"
                    @click="unapprove(requestDetails.id)"
                    >{{ $t("Unapprove") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "ReviewRequest",
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      employeeId: "",
      requests: [],
      employeeImage: "",
      employeeName: "",
      employeeTitle: "",
      requestDetails: {},
      approvetObject: {
        id: "",
        paid_days: "",
        apptoveType: "",
        approve_notes: ""
      }
    };
  },
  methods: {
    getAllrequests() {
      axios
        .get(
          this.getApiUrl +
            "/requestleave/getEmployeeRequests/" +
            this.employeeId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
              //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          this.employeeImage = response.data.data.employee_img;
          this.employeeName = response.data.data.employee_name;
          this.employeeTitle = response.data.data.job_title;
          this.requests = response.data.data.requests;

          if (this.requests.length > 0) {
            this.getrequestData(this.requests[0].id);
          }
        });
    },
    getrequestData(requestId) {
      axios
        .get(this.getApiUrl + "/requestleave/showDetails/" + requestId, {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          console.log(response.data);
          this.requestDetails = response.data.data;
        });
    },
    reject(id) {
      axios
        .get(this.getApiUrl + "/requestleave/declined/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          console.log(response);
          if (response.data.status.error == false) {
            this.requestDetails.status = "Declined";
            this.getAllrequests();
            this.getrequestData(id);
          }
        });
    },
    approve() {
      if (this.valid == false) {
        this.validate();
      } else {
        this.approvetObject.id = this.requestDetails.id;
        this.approvetObject.paid_days = this.requestDetails.request_type;
        this.approvetObject.apptoveType = this.requestDetails.request_type;
        this.approvetObject.approve_notes = this.requestDetails.notes;
        axios
          .post(
            this.getApiUrl + "/requestleave/approve/" + this.approvetObject.id,
            this.approvetObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            console.log(response);
            if (response.data.status.error == false) {
              this.requestDetails.status = "Approved";
              this.getrequestData(this.requestDetails.id);
              this.getAllrequests();
            }
          });
      }
    },
    unapprove(id) {
      axios
        .get(this.getApiUrl + "/requestleave/unapprove/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          if (response.data.status.error == false) {
            this.requestDetails.status = "Pending";
            this.getAllrequests();
            this.getrequestData(id);
          }
        });
    },
    unreject(id) {
      axios
        .get(this.getApiUrl + "/requestleave/Unreject/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          if (response.data.status.error == false) {
            this.requestDetails.status = "Pending";
            this.getAllrequests();
            this.getrequestData(id);
          }
        });
    }
  },
  mounted() {
    this.employeeId = this.$route.query.empId;
    if (this.employeeId) {
      this.getAllrequests();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";

$stats-firstColor: #7297ff;
$stats-secondColor: #ffb400;
$stats-thirdColor: #ff8972;

.previous-requests {
  .v-card {
    height: 93%;
  }
  .employee-info {
    .profile-image {
      border-radius: 50%;
    }
    .name {
    }
    .title {
      font-size: 0.9rem !important;
    }
  }
  .requests-table {
    background-color: transparent !important;
    box-shadow: unset !important;

    tr {
      background-color: transparent !important;
      box-shadow: unset !important;
    }
  }
}

.request {
  .items {
    .item {
      .total-num {
        background-color: $stats-firstColor;
        color: #ffffff !important;
        border-radius: 10px;
      }
    }

    .item1 {
      .total-num {
        background-color: $stats-firstColor;
      }
    }
    .item2 {
      .total-num {
        background-color: $stats-secondColor;
      }
    }
    .item3 {
      .total-num {
        background-color: $stats-thirdColor;
      }
    }
  }

  .v-card {
    .status {
      color: $stats-secondColor;
    }
  }

  .response-header {
    position: relative;
  }

  .form-header::after {
    content: "";
    display: inline-block;
    position: absolute;
    height: 1px;
    width: 36%;
    background-color: #7070701c;
    top: 28px;
    margin: 0 21px;
  }
  .form-header::before {
    content: "";
    display: inline-block;
    position: absolute;
    height: 1px;
    width: 36%;
    background-color: #7070701c;
    top: 28px;
    left: 12px;
    margin: 0 21px;
  }
}

.form-btns {
  .reject {
    background-color: transparent !important;
    border: 2px solid #ff8972;
    color: #ff8972;
  }
  .Approve {
    background-color: $main-color !important;
    color: #ffffff !important;
    border: 2px solid $main-color;
  }
}

.request-btn {
  background-color: transparent !important;
  box-shadow: unset !important;
  color: $main-color !important;
}

.status-blue {
  color: #7297ff;
}
.status-yellow {
  color: #ffb400;
}
.status-red {
  color: #ff8972;
}
.status-green {
  color: #5fb870;
}
</style>
